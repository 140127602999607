@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  button {
    @apply border border-black rounded-xl px-5 py-1
  }
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Lexend", sans-serif;
}

/* SCROLL BAR */
/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #020617;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e92763;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  opacity: 0.8;
}


iframe {
  border: none;
  width: 100%;
  height: 100%;
  position: absolute;
}